/* .outer {
  max-width: 1340px;
  align-self: center;
} */

.outerFull {
  width: 100%;
  margin: auto;
}

.innerBreadcrumb {
  color: #a7a1a1;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  margin: 0px 0px 20px;
}

.innerBreadcrumb span {
  color: #000000;
}

.inner {
  max-width: 1340px;
  align-self: center;
  width: 100%;
}

/* main css track page css start from here */

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 90px;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 565px;
  justify-content: space-between;
}

.dContent {
  height: 100%;
  width: 874px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detailCard {
  width: 100%;
  border-radius: 10px;
  padding: 10px;
}

.dcInfo {
  height: 191px;
  background: linear-gradient(to right, #ffffff 0%, #fafdff 39%, #f6fcff 100%);
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.dcImg {
  width: 175px;
  height: 175px;
  border-radius: 6px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.vocalTag {
  width: 34px;
  height: 30px;
}

.plyBtnContainer {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin: 10px;
}

.infoData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  flex: 1;
}

.iDHtxt {
  font-size: 32px;
  font-weight: bold;
  color: #000;
}

.stxtContainer {
  display: flex;
}

.iDStxt {
  font-size: 14px;
  color: #000;
  opacity: 90%;
}

.idbtnContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.mdbtn {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}

.dcMoreInfo {
  height: 371px;
  background: #f6fcff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.dcmBtnContainer {
  display: flex;
  justify-content: center;
}

.dbtnOuter {
  height: 36px;
  border-radius: 40px;
  border: 1px solid #dbdbdb;
  width: auto;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.dcmBtn {
  width: 105px;
  height: 27px;
  text-align: center;
  color: #000;
  border-radius: 14px;
  padding: 4px 10px;
  font-size: 12px;
  font-family: "Raleway";
  font-weight: 550;
  background-color: transparent;
  border: none;
  transition: all 0.3s ease;
}

.dcmBtn:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.activeBtn {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  font-weight: bold;
}

.dcmData {
  height: 100%;
}

/* details section css start from here ----------------------------------- */

.detailsData {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  /* max-height: 310px; */
  height: 310px;
}

.dtCard {
  width: 422px;
  background-color: #e8f2f8;
  border-radius: 10px;
  height: auto;
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.dtgapclass {
  gap: 10px;
}

.ciHtxt {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: #000;
  font-family: "Raleway";
}

.cisubHtxt {
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.ciPillSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  margin: 4px 0px;
}

.ciPills {
  margin: 0px;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 550;
  color: #000;
  width: fit-content;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
}

/* usage info css start from here ------------------------------ */

.uiContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 310px;
}

.uiHeaderContainer {
  padding: 15px 10px 15px 20px;
  height: auto;
  background-color: #e8f2f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.uiHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.question {
  font-size: 20px;
  font-weight: 550;
  line-height: 16px;
  color: #1b1b1b;
}

.answer {
  color: #000;
  font-size: 14px;
  line-height: 20px;
}

.sfbtn {
  display: flex;
  gap: 8px;
}

.tcBtn {
  width: 100px;
  height: 29px;
  /* background-color: #fff; */
  border-radius: 20px;
  color: #000;
  font-size: 12px;
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  cursor: pointer;
}

.access {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.accessCard {
  width: 422px;
  height: auto;
  background-color: #e8f2f8;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px 10px 10px 20px;
}

.accessHTxt {
  color: #1b1b1b;
  font-size: 14px;
  line-height: 16px;
}

.accessItem {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.allowedIcon {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  background-color: #48b02c;
  border-radius: 50px;
}

.notallowedIcon {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;
  background-color: #f44336;
  border-radius: 50px;
}

.accesstxt {
  color: #1b1b1b;
  line-height: 20px;
  letter-spacing: -0.25px;
  font-size: 14px;
}

/* restricted section css start from here ---------------------------------- */

.resContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 310px;
  flex-wrap: wrap;
}

.rescard {
  width: 276px;
  height: auto;
  background-color: #e8f2f8;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.resHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.resIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
}

.resImg {
  height: 34px;
  width: 34px;
}

.rescross {
  width: 15px;
  height: 15px;
  background-color: #454545;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}

.resHtxt {
  font-size: 14px;
  font-weight: 550;
  color: #0d0d0d;
}

.resSubTxt {
  color: #1d1d1d;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
}

/* pricing cards css start from here ---------------------------- */

.dPricing {
  height: 100%;
  width: 438px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dpCard {
  height: 230px;
  width: 100%;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.dpshortCard {
  background: linear-gradient(to left, #05273f 0%, #1e4d6f 100%);
}

.dpscardContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -webkit-fill-available;
}
.dpbscardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: -webkit-fill-available;
}

.cardHTxt {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  line-height: 40px;
}

.cardSubTxt {
  font-size: 14px;
  color: #ffffff;
  width: 226px;
  line-height: 16px;
}

.pdata {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.pprice {
  font-size: 24px;
  color: #fff;
  font-weight: 550;
  line-height: 40px;
}

.prupee {
  margin-right: 2px;
  margin-bottom: 8px;
  font-size: 16px;
  color: #fff;
  font-weight: 550;
}

.pcpamt {
  font-size: 12px;
  color: #fff;
  text-decoration: line-through;
}

.pdiscount {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 5px;
  background-color: #ffe100;
  font-size: 12px;
  color: #000;
  font-weight: 550;
  letter-spacing: -0.4px;
}

.inclusive {
  font-size: 12px;
  color: #fff;
  opacity: 0.7;
  margin-top: 5px;
}

.gapclass {
  gap: 2px;
}

.dpbroadCard {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

.dbcardHTxt {
  font-size: 22px;
  font-weight: bold;
  color: #000;
  line-height: 40px;
}

.dbcardSubTxt {
  font-size: 14px;
  color: #000;
  width: 226px;
  line-height: 16px;
}

/* restriction section in price section css start from here -------------------------------------- */

.presContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.presHtxt {
  font-size: 14px;
  font-weight: bold;
  color: #1b1b1b;
}

.pres {
  display: flex;
  flex-direction: row;
  gap: 8px;
  overflow: hidden;
  position: relative;
}

.presCard {
  padding: 8px 8px 8px 4px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #00000020;
  white-space: nowrap;
}

.noCard {
  padding: 8px 8px 8px 4px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.presImg {
  width: 25px;
  height: 25px;
}

.pcresHtxt {
  font-size: 14px;
  color: #0d0d0d;
}

.presCountContainer {
  width: auto;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 550;
  color: #c6349b;
  gap: 2px;
}

.presCount {
  text-decoration: underline;
  cursor: pointer;
}

/* tracks n packs displayed based on filter, mood,usecase etc css start from here ---------------------------------- */

.catsTracks {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}

.catsCards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 20px 0px;
  flex-wrap: wrap;
}

.strackHtxt {
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway";
  color: #220700;
}

.packHtxt {
  color: #010101;
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway";
}

.descriptionP {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dpHtxt {
  color: #010101;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  opacity: 80%;
}

.vaTxt {
  color: #373737;
  font-size: 16px;
  font-weight: 550;
  font-family: "Raleway";
  margin: 0;
  cursor: pointer;
}

.viewall {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

/* more details video modal -------------------------------------------------------------- */
.modalOverlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContainer,
.videoWrapper {
  width: 940px;
  height: 546px;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px 20px;
}

.introVideo {
  width: 970px;
  height: 546px;
}

.modalClose {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 54px;
  color: #fff;
}

/* create playlist pop css start from here -------------------------------------------------------------------------------------------------------- */

@keyframes slideInAndShake {
  0% {
    transform: translateY(-100%) translateX(0);
    opacity: 0;
  }
  50% {
    transform: translateY(20px) translateX(-10px);
    opacity: 1;
  }
  70% {
    transform: translateY(10px) translateX(10px);
  }
  90% {
    transform: translateY(0px) translateX(-5px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

.collectionContainer {
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
}

.collectionContent {
  width: 389px;
  height: auto;
  max-height: 476px;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Animation */
  animation: slideInAndShake 0.4s ease-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.popupHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pHText {
  font-weight: bold;
  font-family: "Raleway";
  font-size: 26px;
}

.cpinput {
  width: 334px;
  height: 59px;
  border-radius: 8px;
  background-color: #fff;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 400;
  border: 1px solid #fff;
  padding-left: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  outline: none;
}

.cpinput:focus {
  outline: none;
}

.existingCollection {
  width: 334px;
  height: 60px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  padding: 4px;
}

.playlistHeading {
  font-family: "Raleway";
  font-size: 18px;
  font-weight: 550;
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 5px;
}

.pimagecard {
  border-radius: 5px;
  width: 80px;
  height: 52px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.existingpsection {
  max-height: 390px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px;
}

.rcontainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.rcard {
  width: 252px;
  height: 152px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.rHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.rIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  background-color: #dadada;
  border-radius: 50%;
}

.rImg {
  height: 24px;
  width: 24px;
}

.rHtxt {
  font-size: 14px;
  font-weight: 550;
  color: #0d0d0d;
}

.rSubTxt {
  color: #1d1d1d;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
}

/* responsive screens css start from here ----------------------------------------- */

@media (max-width: 768px) {
  .outerFull {
    width: 100%;
  }
  .inner {
    width: 100%;
    align-items: center;
  }
  .container {
    margin-top: 70px;
  }
  .catsTracks {
    padding: 0px 15px;
  }
  .modalContainer,
  .introVideo,
  .videoWrapper {
    width: 500px;
    height: 300px;
  }
  .detailsContainer {
    flex-direction: column;
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    gap: 20px;
  }
  .dContent,
  .dPricing,
  .pres {
    width: 758px;
  }
  .dPricing {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .dpCard {
    width: 370px;
  }
  .cardHTxt,
  .dbcardHTxt {
    font-size: 20px;
  }
  .cardSubTxt,
  .dbcardSubTxt {
    font-size: 12px;
  }
  .dpscardContent {
    gap: 5px;
  }
  .presCountContainer {
    right: 20px;
  }
  .accessCard {
    width: 362px;
  }
  .rescard {
    width: 235px;
  }
  .catsTracks {
    padding: 0px 15px;
  }
}

@media (max-width: 481px) {
  .modalContainer,
  .introVideo,
  .videoWrapper {
    width: 300px;
    height: 180px;
    padding: 5px 10px;
  }
  .dContent,
  .dPricing,
  .pres {
    width: 461px;
  }
  .dcInfo {
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .infoData {
    align-items: center;
  }
  .idbtnContainer {
    /* flex-direction: row; */
    align-items: center;
    width: 390px;
  }
  .mdbtn {
    padding: 0px;
  }
  .dcMoreInfo {
    height: auto;
  }
  .detailsData {
    flex-direction: column-reverse;
    height: 200px;
    align-items: center;
    padding: 0px 10px;
  }
  .detailsData.expanded {
    height: auto;
  }
  .dtCard {
    width: 400px;
  }
  .vmorebtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 30px;
    padding: 5px 10px;
    gap: 8px;
    background-color: #fff;
    border-radius: 20px;
    font-size: 12px;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    font-weight: 550;
  }
  .access {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .accessCard {
    width: -webkit-fill-available;
  }
  .uiContainer {
    padding: 0px 20px;
  }
  .sfbtn {
    display: none;
  }
  .uiHeaderContainer {
    gap: 15px;
  }
  .rescard {
    width: 200px;
  }
  .resContainer {
    justify-content: center;
    margin-left: 4px;
  }
  .dPricing {
    margin-top: 20px;
    padding: 0px 30px;
  }
  .dpCard {
    width: 100%;
  }
  .presCountContainer {
    right: 0px;
    left: 200px;
  }
  .packHtxt {
    font-size: 20px;
  }
  .dpHtxt {
    font-size: 11px;
  }
  .vaTxt {
    font-size: 11px;
  }
  .strackHtxt {
    font-size: 20px;
  }
  .stxtContainer {
    justify-content: center;
  }
}

@media (max-width: 390px) {
  .dContent,
  .dPricing,
  .pres,
  .idbtnContainer {
    width: 370px;
  }
  .dtCard {
    width: 340px;
  }
  .uiContainer {
    padding: 0px;
  }
  .rescard {
    width: 164px;
  }
  .resImg {
    width: 20px;
    height: 20px;
  }
  .resIcon {
    width: 40px;
    height: 40px;
  }
  .dPricing {
    padding: 0px;
  }
}

@media (max-width: 375px) {
  .modalContainer,
  .introVideo,
  .videoWrapper {
    width: 300px;
    height: 180px;
    padding: 5px 10px;
  }
  .dContent,
  .dPricing,
  .pres,
  .idbtnContainer {
    width: 355px;
  }
  .dtCard {
    width: 315px;
  }
  .uiContainer {
    padding: 0px;
  }
  .rescard {
    width: 150px;
  }
  .resImg {
    width: 20px;
    height: 20px;
  }
  .resIcon {
    width: 40px;
    height: 40px;
  }
  .dPricing {
    padding: 0px;
  }
}

@media (max-width: 430px) {
  .catscardslice {
    margin-left: 22px;
  }
}

@media (max-width: 414px) {
  .catscardslice {
    margin-left: 16px;
  }
}

@media (max-width: 390px) {
  .catscardslice {
    margin-left: 4px;
  }
}

@media (max-width: 320px) {
  .modalContainer,
  .introVideo,
  .videoWrapper {
    width: 250px;
    height: 140px;
    padding: 5px 10px;
  }
  .dContent,
  .dPricing,
  .pres,
  .idbtnContainer {
    width: 300px;
  }
  .iDHtxt {
    font-size: 22px;
  }
  .dcmBtn {
    width: 82px;
  }
  .dtCard {
    width: 250px;
  }
  .uiContainer {
    padding: 0px;
  }
  .rescard {
    width: 125px;
  }
  .resHtxt {
    font-size: 12px;
  }
  .resImg {
    width: 20px;
    height: 20px;
  }
  .resIcon {
    width: 40px;
    height: 40px;
  }
  .dPricing {
    padding: 0px;
  }
  .cardHTxt,
  .dbcardHTxt {
    font-size: 14px;
    line-height: 20px;
  }
  .cardSubTxt,
  .dbcardSubTxt {
    font-size: 8px;
    line-height: 13px;
    width: 160px;
  }
  .pprice {
    font-size: 20px;
    line-height: 20px;
  }
  .packHtxt {
    font-size: 14px;
  }
  .dpHtxt {
    font-size: 8px;
  }
}
