.header-container {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  font-family: "Raleway";
}

/* header-banner-about-section ----starts from here -------------------------------------- */

.header-banner-about-section {
  height: 35px;
  background-color: rgba(34, 7, 0, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Raleway";
  color: #fff;
  padding: 0px 20px;
}

.header-banner {
  display: flex;
  align-items: center;
  gap: 10px;
}

.banner-icon {
  height: 17px;
  width: 17px;
}

.header-about-tabs {
  display: flex;
  gap: 30px;
}

.header-about-tabs span {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

/* header-banner-about-section ----ends from here -------------------------------------- */

/* header-main-section ----starts from here -------------------------------------- */

.header-main-section {
  height: 65px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: "Raleway";
  color: #030303;
  padding: 0px 20px;
  font-size: 16px;
  font-weight: 550;
  z-index: 999;
  position: static;
}

.header-logo-tabs {
  display: flex;
  align-items: center;
  gap: 20px;
  z-index: 999;
  width: 100%;
}

.header-logo-tabs-mobile {
  /* display: flex;
  align-items: center;
  gap: 20px;
  z-index: 999; */
  justify-content: space-between;
  /* width: 100%; */
}

.menu-icon {
  display: none;
}

.mobile-icons {
  display: none;
}

.header-logo {
  width: 135px;
  cursor: pointer;
  height: 40px
}

.header-tabs {
  display: flex;
  align-items: center;
  /* gap: 5px; */
  height: 54px;
}

.header-tab-span {
  display: flex;
  gap: 25px;
  cursor: pointer;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0px 12px;
  /* background: red; */
}

.header-tab-span span {
  cursor: pointer;
  font-size: 16px;
  font-weight: 550;
  /* min-width: 60px; */
}

.header-tab-span span:hover {
  color: var(--button-primary);
  /* padding-bottom: 10px; */
  /* text-decoration-line: underline; */
  /* font-style: underline; */
  /* font-weight: 550; */
}

.hover-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: fit-content;
  height: 330px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: none;
  flex-direction: row;
  gap: 20px;
  z-index: 1;
  border-radius: 8px;
}

.hc-packs {
  width: max-content;
  height: auto;
  padding: 20px 25px;
  /* height: fit-content; */
}

.header-tab-span:hover .hover-content {
  display: flex;
}

/* When clicked, disable hover */
.header-tab-span.no-hover:hover .hover-content {
  display: none !important;
}

/* Hide hover-content explicitly on click */
.header-tab-span.no-hover .hover-content {
  display: none !important;
}

.hover-content h3 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.hover-content p {
  font-size: 14px;
  /* color: #666; */
}

.explore-button {
  background-color: var(--button-primary);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.explore-button:hover {
  background-color: #a53081;
}

/* header-main-section ----ends from here -------------------------------------- */

/* smashhits headers hover content start from here */
/* --------------------------------------------------------------------------- */

.smashits-subheader-container-temp {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: #4d4b4b;
  border-radius: 8px;
}

.smashits-upcoming {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 289px;
  width: 246px;
}

.smashits-upcoming p {
  font-size: 16px;
  font-family: "Raleway";
  font-weight: 700;
  color: #000;
  /* opacity: 60%; */
  margin: 0px;
  margin-bottom: 5px;
}

.smashits-upcoming-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.smashits-upcoming-content:hover {
  color: var(--button-primary);
  font-weight: 550;
  background-color: #f0f6fa;
  /* background-color: #F00; */
  font-size: 14px;
}

.smashits-upcoming-content p:hover {
  color: var(--button-primary);
  font-weight: 700;
}

.smashits-upcoming-image {
  width: 38px;
  height: 38px;
  /* background-color: #d9d9d9; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 2px;
}

.smashits-upcoming-content p {
  /* color: #4c4c4c; */
  color: #000;
  font-size: 14px;
  font-family: "Raleway";
  margin-top: 5px;
  /* opacity: 80%; */
  font-weight: normal;
}

.smashits-banners-container {
  display: flex;
  height: 289px;
  width: 266px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 8px;
  gap: 5px;
}

.smashits-banners {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 141px;
  width: 120px;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 12px;
  font-family: "Raleway";
  font-weight: 400;
  color: #585858;
  border: 1px solid #eaeaea;
}

.calendar-event {
  height: 38px;
  width: 38px;
  background: #e4e2e2;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  font-family: "Inter";
  /* border: 1px solid #000; */
  border-radius: 4px;
}

.calendar-event-month {
  font-size: 11px;
  font-weight: 700;
  color: #FFF;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  height: 40%;
  justify-content: center;
  display: flex;
  border-radius: 4px 4px 0px 0px;
  align-items: center;
}

.calendar-event-date {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  justify-content: center;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  flex: 1;
}

/*  MONTH BASED CLASSES */
.jan {
  background: #0A1172;
}

.january {
  color: #0A1172;
}

.feb {
  background: #701C5D;
}

.february {
  color: #701C5D;
}

.mar{
  background: #228B22;
}

.march {
  color: #228B22;
}

.apr {
  background: #004F5D;
}

.april {
  color: #004F5D;
}

.may {
  background: #DC143C;
}

.may-t {
  color: #DC143C;
}

.jun {
  background: #D35400;
}

.june {
  color: #D35400;
}

.jul{
  background: #4169E1;
}

.july {
  color: #4169E1;
}

.aug {
  background: #008080;
}

.august {
  color: #008080;
}

.sep {
  background: #556B2F;
}

.september {
  color: #556B2F;
}

.oct {
  background: #E97451;
}

.october {
  color: #E97451;
}

.nov {
  background: #654321;
}

.november {
  color: #654321;
}

.dec {
  background: #800020;
}

.december {
  color: #800020;
}

/* ---------------------------------------------------------------------------- */
/* smashhits headers hover content ends from here */

/* packs headers hover content starts from here ---------------------------------- */

.pack-sub-header-container {
  display: flex;
  flex-direction: row;
  width: 610px;
  height: 280px;
  /* justify-content: space-between; */
  gap: 15px;
}

.pack-sub-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pack-sub-header-p {
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  /* color: #4c4c4c; */
  color: #000;
  margin: 0px;
  text-align: left;
  padding: 5px 10px 5px 5px;
  min-width: 200px;
}

.pack-sub-header-p:hover {
  font-weight: 700;
  background-color: #f0f6fa;
  color: var(--button-primary);
}

.smashits-subheader-p {
  font-size: 14px;
  font-weight: 700;
  font-family: "Raleway";
  /* color: #c5c5c5; */
  /* color: #000; */
  margin: 0px;
  margin-bottom: 8px;
}

/* packs headers hover content ends from here ------------------------------------------ */

/* filter tracks headers hover content start from here */

.ft-track-width {
  width: 750px;
  height: 310px;
}

.ft-subheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}

.ft-by-budget {
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 278px;
}

.ft-by-budget p {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 700;
  color: #000;
  margin: 0px;
  margin-bottom: 10px;
}

.ft-by-budget-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ft-by-budget-list-item {
  display: flex;
  padding: 10px;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  width: 300px;
  height: 45px;
}

.ft-by-budget-list-item:hover {
  background-color: #f0f6fa;
}

.ft-by-budget-list-item span {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  color: #2e2e2e;
}

.ft-by-budget-list-item span:hover {
  font-weight: bold;
  color: var(--button-primary);
}

.ft-by-type {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 278px;
}

.ft-by-type p {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 700;
  color: #000;
  margin: 0px;
  margin-bottom: 10px;
}

.ft-by-type-list {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  border-radius: 5px;
  width: 100px;
  height: 100%;
  /* justify-content: space-between; */
  gap: 20px;
}

.ft-type-list-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ft-by-type-list-item {
  display: flex;
  /* background-color: #ccc; */
  border-radius: 5px;
  width: 93px;
  height: 93px;
}

.ft-by-type-list-item img {
  display: flex;
  /* background-color: #ccc; */
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.ft-by-language {
  display: flex;
  flex-direction: column;
  width: 238px;
  height: 308px;
}

.ft-by-language p {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 700;
  color: #000;
  margin: 0px;
  margin-bottom: 10px;
}

.ft-by-language-list-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
}

.ft-by-language-list {
  display: flex;
  flex-direction: column;
  width: 104px;
  height: 100%;
}

.ft-by-language-list div:hover {
  color: var(--button-primary);
  background-color: #f0f6fa;
  font-weight: 550;
}

.ft-by-language-list p:hover {
  align-items: center;
  color: var(--button-primary);
  font-weight: bold;
  /* margin-top: 5px; */
}

.ft-by-language-list p {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  color: #4c4c4c;
  padding: 5px;
}
/* filter tracks headers hover content ends from here */

/* occasions headers will start from here ------------------------------------------------ */

.occasion-width {
  width: 930px;
  height: 400px;
}

.occasion-subheader-container {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 10px;
  /* background-color: #a53081; */
  flex-wrap: wrap;
  flex-direction: column;
}

.occasion-list {
  display: flex;
  flex-direction: column;
  min-width: 167px;
  max-width: 172px;
  gap: 5px;
  box-sizing: border-box;
  /* background-color: #ccc; */
}

.occasion-list p {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 700;
  /* color: #FFF; */
  margin-top: 8px;
  /* padding: 2px 5px; */
}

.occasion-list span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway";
  padding: 5px;
}

.occasion-list span:hover {
  font-weight: 700;
  font-size: 14px;
}

.occasion-list a:hover {
  color: var(--button-primary);
  font-weight: 700;
  background-color: #f0f6fa;
}

/* occasions headers will end from here ------------------------------------------------ */

/* categories headers will start from here ------------------------------------------------ */

.cate-width {
  width: max-content;
  padding: 0px;
  /* height: 330px; */
  /* min-height: 300px; */
  /* max-height: 450px; */
  height: auto;
}

.categories-subheader-container {
  max-height: 520px;
  /* position: relative; */
  /* padding-left: 20px */
}

.categories-subheader-heading {
  background-color: #fff;
  height: 46px;
  display: flex;
  flex-direction: row;
  gap: 35px;
  align-items: flex-end;
  cursor: pointer;
  padding-bottom: 8px;
  padding-left: 20px;
}

.categories-subheader-heading span {
  transition: color 0.3s ease;
  color: #000;
  font-size: 16px;
  font-family: "Raleway";
  font-weight: bold;
}

.categories-subheader-items {
  background-color: #f4f4f4;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  /* width: 900px; */
  height: 320px;
  padding: 10px 20px 20px;
}

.cs-item-list-mood p,
.cs-item-list-genre p,
.cs-item-list-usecase p {
  margin: 0;
  color: #000;
}

.cs-item-list-mood p:hover,
.cs-item-list-genre p:hover,
.cs-item-list-usecase p:hover {
  color: var(--button-primary);
  font-weight: 550;
}

.cs-item-list-mood {
  width: 150px;
  background-color: #f4f4f4;
  padding: 2px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.cs-item-list-mood p {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  /* color: #4c4c4c; */
}

.cs-item-list-usecase {
  width: 150px;
  background-color: #f4f4f4;
  padding: 2px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* categories headers will end from here ------------------------------------------------ */

.header-login-cart {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0px;
}

.user-dropdown-container {
  position: relative;
  background-color: #ffebf1;
  padding: 10px 10px 5px;
  border-radius: 50%;
}

.user-icon {
  cursor: pointer;
  color: var(--button-primary);
  background-color: #ffebf1;
  /* color:  #FFEBF1;
  background-color: var(--button-primary); */
  border-radius: 50%;
}

.user-dropdown-container:hover .user-dropdown {
  display: block;
}

.user-dropdown {
  display: none;
  position: absolute;
  /* top: 30px; */
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 1000;
  width: 160px;
}

.user-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-dropdown li {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.user-dropdown li:hover {
  background: #f0f6fa;
  color: var(--button-primary);
  border-radius: 2px;
  font-weight: 550;
}

li.logout {
  background: #ffebf1;
}

li.logout:hover {
  background: var(--button-primary);
  color: #fff;
  border-radius: 2px;
  font-weight: 550;
}

.cart-icon-container {
  position: relative; /* This ensures the badge is positioned relative to this container */
  display: inline-block;
}

.cart-icon {
  width: 22px;
  height: 27px;
  cursor: pointer;
}

.cartCountHeader {
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 9px;
  font-weight: bold;
  font-family: "Inter";
  background-color: var(--button-primary);
  border-radius: 50px;
  position: absolute;
  /* top: 0; */
  bottom: -3px; /* Adjust to align correctly */
  right: -3px; /* Adjust to align correctly */
}

/* EXPLORE HEADER SECTION */
.explore-width {
  width: 400px;
  height: max-content;
  padding: 10px 20px;
}

.explore-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0px 0px 20px 0px;
}

.explore-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  /* gap: 10px; */
}

.explore-list {
  display: flex;
  flex-direction: column;
  width: 104px;
  height: 100%;
}

.explore-list-item img {
  height: 167px;
  width: 167px;
}

.explore-section img {
  border-radius: 5px;
}

.explore-section span {
  font-size: 14px;
  font-family: "Raleway";
  padding: 5px 5px;
}

.explore-section:hover {
  background-color: #f0f6fa;
  color: var(--button-primary);
  font-weight: bold;
}

/* SUPPORT HEADER */
.support-width {
  width: 245px;
  height: max-content;
  right: 0;
  left: unset;
  /* padding: 30px 20px 0px; */
  /* margin: 5px 0px 0px; */
}

.support-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.support-list-item {
  display: flex;
  /* background-color: #ccc; */
  border-radius: 5px;
  /* width: 230px; */
  height: 150px;
}

.support-list p {
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  color: #000;
  padding: 5px;
}

.support-wrapper a:hover {
  color: var(--button-primary);
  background-color: #f0f6fa;
  font-weight: 550;
}

.support-wrapper p:hover {
  color: var(--button-primary);
  font-weight: 550;
}

.demo-r {
  width: auto;
  height: auto;
  white-space: nowrap;
  padding: 10px 20px;
  border: 1px solid #00000044;
  border-radius: 30px;
  cursor: pointer;
  font-size: 14px;
  /* background: var(--button-primary); */
  /* opacity: 0.5; */
  color: #000000;
}

.support-icon-holder {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e4e2e2;
  opacity: 0.8;
}

.support-list-p {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  margin: 0;
}

/* mobile view ---------------------------------------------------------------- */

@media (max-width: 768px) {
  .header-banner-about-section {
    display: none;
  }

  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .header-logo {
    width: 135px;
    cursor: pointer;
    height: 40px;
  }

  .header-login-cart {
    display: none;
  }

  /* .header-main-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .header-logo-tabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 99;
  } */

  .header-logo-tabs {
    justify-content: space-between;
  }

  .header-tabs {
    display: none; /* Hide desktop tabs */
  }

  .mobile-menu-logo {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  /* Sidebar menu */
  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-end;
    z-index: 1;
  }

  .mobile-menu-content {
    background: white;
    width: 100%;
    /* max-width: 250px; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
  }

  /* Close icon styling FOR MOBILE VIEW */
  .close-icon {
    cursor: pointer;
    align-self: flex-start;
    color: #030303;
  }

  .mobile-menu-content span {
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    padding: 10px 0;
    /* border-bottom: 1px solid #ddd; */
    font-weight: bold;
  }

  /* Container for the button at the bottom of the content */
  .custom-button-container {
    width: 100%;
    margin-top: auto;
    padding: 0 20px;
    display: flex;
    justify-content: center;
  }

  .custom-button-container .custom-button {
    width: 100%;
  }

  .mobile-menu-logo {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mobile-icons {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .search-icon {
    cursor: pointer;
  }

  /* Mobile SearchBar */
  .mobile-search-bar {
    background-color: #fff;
    padding: 10px 20px;
    width: 100%;
  }

  .mobilehl2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .mobilemenucontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    align-items: center;
  }

  .msubmenutxt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
  }

  .msubmenu {
    flex-grow: 1;
    max-height: 90vh; /* Limit height to enable scrolling */
    overflow-y: auto;
  }

  .mobile-submenu {
    display: flex;
    flex-direction: column;
    max-height: 99vh; /* Ensure it takes available space */
    overflow: hidden; /* Prevent unnecessary overflow */
  }
}
